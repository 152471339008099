<template>
  <div class="question-generator">
    <transition
      name="question"
      v-for="(q, qKey) in filteredQuestions"
      :key="qKey + q.name"
      :enter-active-class="`animate__animated ${enterClass}`"
      :leave-active-class="`animate__animated ${leaveClass}`"
    >
      <div
        class="question-generator__question-wrapper max-w-840 th-mx-auto"
        v-show="qKey + 1 === currentQuestion"
      >
        <div class="w-100 th-mb-16">
          <alert-generator
            v-for="(alert, alertK) in q.alerts"
            :key="qKey + alertK"
            :alert="alert"
            :model="model"
          />
        </div>
        <th-card
          class="question-generator__question flex-grow-1 w-100"
          body-classes="th-px-16 th-px-24-md th-py-24"
          header-classes="th-py-16"
          header-line
        >
          <h6 slot="header" class="subtitle1 th-mb-0">
            {{
              $translation.t("Step {step} of {total}", {
                step: qKey + 1,
                total: totalQuestions,
              })
            }}
            -
            {{ $translation.t(q.name) }}
          </h6>
          <v-form
            :ref="`question_${qKey + 1}_form`"
            :key="`question_${qKey + 1}_form`"
            slot="body"
          >
            <div
              class="th-mb-12 d-flex flex-column flex-md-row align-items-md-center"
              v-if="q.title || q.tooltip"
            >
              <h5
                class="th-m-0 body2"
                v-if="q.title"
                v-html="$translation.t(q.title)"
              ></h5>
              <w-tooltip
                class="th-ml-8-md"
                v-if="q.tooltip"
                :content="$translation.t(q.tooltip)"
              />
            </div>
            <div class="row" :class="q.classes">
              <div
                class="col-12 th-py-0"
                v-for="(input, inputKey) in q.inputs.filter((inp) =>
                  conditionalResult(inp, model)
                )"
                :key="qKey + inputKey + input.model"
                :class="input.classes"
              >
                <question-input-field
                  :ref="`question_${qKey + 1}_input`"
                  :input="input"
                  class="th-py-8"
                  :document-model="model"
                  v-model="model[input.model]"
                  @nextQuestion="triggerNext(q)"
                  :current-errors="currentErrors"
                />
              </div>
            </div>
            <div
              v-if="q.html"
              v-html="q.html.content"
              v-show="conditionalResult(q.html, model)"
            ></div>
            <p
              class="text-danger th-mt-12"
              v-if="errors[currentQuestion]"
              v-html="errors[currentQuestion]"
            ></p>
            <div
              class="d-flex flex-column-reverse flex-sm-row align-items-center justify-content-between th-mt-20 w-100"
            >
              <v-btn
                color="secondaryDark"
                class="th-mt-12 th-mt-0-sm"
                text
                @click="back(q)"
                v-if="currentQuestion > 1"
                :disabled="loading"
                :block="$vuetify.breakpoint.smAndDown"
                @mouseenter="
                  stepAnimation(currentQuestion - 1, currentQuestion)
                "
              >
                {{ $translation.t("Back") }}
              </v-btn>

              <div
                class="d-flex flex-column-reverse flex-sm-row align-items-center justify-content-end w-100"
              >
                <component
                  v-for="(action, actionK) in q.actions"
                  :key="action.cta + qKey + actionK"
                  :is="action.component"
                  :block="$vuetify.breakpoint.smAndDown"
                  class="th-mt-12 th-mt-0-sm"
                  v-bind="action.params(self)"
                >
                  {{ $translation.t(action.cta) }}
                </component>

                <v-btn
                  color="primary"
                  @click="next(q)"
                  :block="$vuetify.breakpoint.smAndDown"
                  @mouseenter="
                    stepAnimation(currentQuestion + 1, currentQuestion)
                  "
                  :loading="loading"
                  large
                >
                  {{
                    isLastQuestion
                      ? cta || $translation.t("submit")
                      : $translation.t("continue")
                  }}
                </v-btn>
              </div>
            </div>
          </v-form>
        </th-card>
      </div>
    </transition>
  </div>
</template>

<script>
import QuestionInputField from "./QuestionInputField.vue";
import { isFunction } from "lodash";
import WTooltip from "../../GeneralComponents/WTooltip";
import AlertGenerator from "./AlertGenerator";
import ThCard from "../../GeneralComponents/ThCard";
import { conditionalResult } from "../../../utils/questionGeneratorHelpers";

export default {
  name: "QuestionGenerator",
  components: {
    ThCard,
    AlertGenerator,
    WTooltip,
    QuestionInputField,
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
      required: true,
    },
    // Current Question Number
    currentQuestionnaireStep: [String, Number],
    currentErrors: {
      type: Object,
      default: () => ({}),
    },
    // Questionnaire Model
    value: Object,
    cta: String,
    loading: Boolean,
  },
  data: () => ({
    self: null,
    model: {},
    currentQuestion: 1,
    enterClass: "animate__slideInUp",
    leaveClass: "animate__slideOutUp",
    errors: {},
  }),
  created() {
    this.self = this;
    this.currentQuestion = this.currentQuestionnaireStep || 1;

    // Object.assign(this.model, this.value);
    this.model = {
      ...this.value,
    };

    this.questions.forEach(({ inputs }) => {
      inputs.forEach((input) => {
        this.$set(
          this.model,
          input.model,
          this.value[input.model] ?? input.default ?? null
        );
      });
    });

    // Object.assign(this.model, this.value || {});
    // this.$store.commit("docGen/SET_CURRENT_QUESTIONS", this.questions);
  },
  computed: {
    totalQuestions() {
      return this.filteredQuestions.length;
    },
    isLastQuestion() {
      return this.totalQuestions === this.currentQuestion;
    },
    filteredQuestions() {
      return this.questions.filter((q) => conditionalResult(q, this.model));
    },
  },
  methods: {
    conditionalResult,
    triggerNext(q) {
      this.stepAnimation(this.currentQuestion + 1, this.currentQuestion);
      this.next(q);
    },
    next(q = null) {
      try {
        const questionForm = [
          ...this.$refs[`question_${this.currentQuestion}_form`],
        ].pop();

        if (questionForm && !questionForm.validate()) return;
      } catch (e) {
        console.warn("Question validation failed", e);
        this.$log.notify(e);
      }

      try {
        if (q && isFunction(q.validate)) {
          let errorMessage = q.validate(this.model);

          errorMessage = errorMessage !== true ? errorMessage : null;

          this.$set(this.errors, this.currentQuestion, errorMessage);
          if (errorMessage) return;
        }
      } catch (e) {
        console.warn("Question validation failed", e);
        this.$log.notify(e);
      }

      try {
        const questionInputValidation =
          this.$refs[`question_${this.currentQuestion}_input`];
        if (questionInputValidation.find((input) => !input.validate())) {
          return;
        }
      } catch (e) {
        this.$log.notify(e);
      }

      if (this.isLastQuestion) {
        this.$emit("submit", this.model);
        return;
      }

      this.currentQuestion++;

      // Scroll to top
      window.scrollTo({
        top: 50,
        left: 0,
        behavior: "smooth",
      });

      this.$emit("next", { q, questionNumber: this.currentQuestion });
    },
    back(q) {
      if (this.currentQuestion > 1) {
        this.currentQuestion--;
        this.$emit("previous", {
          q,
          questionNumber: this.currentQuestion,
        });
      }
    },
    stepAnimation(newStep, oldStep) {
      if (newStep > oldStep) {
        this.enterClass = "animate__slideInUp";
        this.leaveClass = "animate__slideOutUp";
      } else {
        this.enterClass = "animate__slideInDown";
        this.leaveClass = "animate__slideOutDown";
      }
    },
  },
  watch: {
    currentQuestionnaireStep(newQuestionNumber) {
      this.currentQuestion = newQuestionNumber;
    },
    model: {
      handler: function (newModel) {
        this.$emit("input", newModel);
      },
      deep: true,
    },
    filteredQuestions(newQuestions) {
      if (this.loading !== true)
        this.$store.commit("questionnaire/SET_QUESTIONS", newQuestions);
    },
  },
};
</script>

<style lang="scss" scoped>
/*@import "@/scss/abstract/spa_utilities";*/

.question-generator {
  position: relative;
  overflow: auto;
  @include fullHeight("50px");

  &__question-wrapper {
    width: 840px;
    overflow: auto;
    height: 100%;
  }
}
.question-alert {
  &:not(:first-child) {
    margin-top: 12px;
  }
}
</style>

<style lang="scss">
.animate__animated {
  position: absolute;
}
</style>
