<template>
  <div class="d-inline-block">
    <v-tooltip top class="d-none d-md-inline-block" max-width="300">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="primary" size="20">mdi-information</v-icon>
      </template>
      <span class="caption" v-html="content"></span>
    </v-tooltip>
    <p class="d-md-none caption" v-html="content"></p>
  </div>
</template>

<script>
export default {
  name: "WTooltip",
  props: {
    content: [String, Object],
  },
};
</script>
