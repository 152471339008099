import { escapeRegExp } from "lodash";

export function isTrue(answer) {
  return [true, "true", "yes", "taip"].includes(
    typeof answer === "string" ? answer?.toLowerCase() : answer
  );
}

export function isFalse(answer) {
  return [false, "false", "no", "ne"].includes(
    typeof answer === "string" ? answer?.toLowerCase() : answer
  );
}

export const conditionalClauses = [
  "EQUAL ==",
  "NOT EQUAL !=",
  "MORE THAN >",
  "LESS THAN <",
  "IS YES",
  "IS NO",
  "CONTAINS",
];

export const getConditionalFunction = (condition) => {
  switch (condition.condition) {
    case "EQUAL ==":
      return (modelValue) => modelValue === condition.value;
    case "NOT EQUAL !=":
      return (modelValue) => modelValue !== condition.value;
    case "MORE THAN >":
      return (modelValue) => modelValue > condition.value;
    case "LESS THAN <":
      return (modelValue) => modelValue < condition.value;
    case "IS YES":
      return (modelValue) => isTrue(modelValue);
    case "IS NO":
      return (modelValue) => isFalse(modelValue);
    case "CONTAINS":
      return (modelValue) => {
        if (Array.isArray(modelValue)) {
          return modelValue.indexOf(condition.value) >= 0;
        }

        const rexExp = new RegExp(escapeRegExp(condition.value));
        return !!rexExp.test(modelValue);
      };
    default:
      return () => true;
  }
};

export const conditionalResult = (input, model = {}) => {
  // No Conditions exists
  if (!input.condition && !input.conditions) {
    return true;
  }

  let show = true;

  if (input.condition && typeof input.condition === "function")
    show = input.condition(model);

  if (input.conditions) {
    show = input.conditions.reduce(function (result, currentCondition) {
      const conditionalFunction = getConditionalFunction(currentCondition);
      // console.log('function from condition', {
      //     conditionalFunction,
      //     modelKey: currentCondition.variable,
      //     modelValue: model[currentCondition.variable],
      //     prevRes: result,
      //     currentCondition,
      //     res: conditionalFunction(model[currentCondition.variable]),
      //     overall: result &&
      //         conditionalFunction(model[currentCondition.variable])
      // });

      return result && conditionalFunction(model[currentCondition.variable]);
    }, true);
  }

  if (!show && input.default) {
    model[input.model] = input.default;
  }

  return show;
};
