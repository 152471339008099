<template>
  <v-dialog max-width="540" v-model="open">
    <th-card>
      <div slot="body" class="d-flex flex-column align-items-center">
        <img
          width="75"
          :src="require('@/assets/images/statuses/cross-danger.svg')"
          alt="Error"
        />
        <h3 class="th-mt-12 text-center">
          {{ $translation.t("Unexpected error occurred") }}
        </h3>
        <p v-if="error" class="text-danger text-center th-mt-12">
          {{ $translation.t(error) }}
        </p>
        <ul
          v-if="Object.values(currentErrors || {}).length > 0"
          class="th-mt-12"
        >
          <li
            class="text-danger"
            v-for="(errors, eKey) in currentErrors"
            :key="eKey"
          >
            {{ errors.map((e) => $translation.t(e)).join(", ") }}
          </li>
        </ul>
        <v-btn color="primary" outlined class="th-mt-24" @click="open = false">
          {{ $translation.t("Fix") }}
        </v-btn>
      </div>
    </th-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import ThCard from "../GeneralComponents/ThCard";

export default {
  name: "QuestionErrorModal",
  components: { ThCard },
  props: {
    error: String,
    value: Boolean,
  },
  data: () => ({
    open: false,
  }),
  computed: {
    ...mapState("questionnaire", ["currentErrors"]),
  },
  watch: {
    open(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.open = newValue;
    },
  },
};
</script>

<style scoped></style>
