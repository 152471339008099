<template>
  <div>
    <div
      class="th-mb-8 d-flex flex-column flex-md-row align-items-start"
      v-if="input.title || input.tooltip"
    >
      <h5
        class="th-m-0 body2"
        v-if="input.title"
        v-html="$translation.t(inputTitleResult(input.title))"
      ></h5>
      <w-tooltip
        class="th-ml-8-md"
        v-if="input.tooltip"
        :content="$translation.t(input.tooltip)"
      />
    </div>
    <component
      :is="component.component"
      v-bind="component.binding"
      v-model="model"
      @nextQuestion="$emit('nextQuestion')"
      :ref="`input_${inputKey}`"
    />
    <p class="text-danger th-mt-12" v-if="error">{{ error }}</p>
    <p
      class="text-danger th-mt-12"
      v-for="(e, eKey) in existingErrors"
      :key="eKey"
    >
      {{ e }}
    </p>
  </div>
</template>

<script>
import { isFunction } from "lodash";
import WTooltip from "../../GeneralComponents/WTooltip";
import { keyToValidationFunction } from "@/utils/inputRules";
export default {
  name: "QuestionInputField",
  components: { WTooltip },
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: {
      default: false,
    },
    documentModel: {
      type: Object,
      default: () => ({}),
    },
    currentErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    model: null,
    error: "",
  }),
  created() {
    this.model = this.value;
  },
  computed: {
    component() {
      switch (this.input.type) {
        case "radio":
          return {
            binding: { input: this.transformedInputs },
            component: () => import("../../FormFields/RadioInput.vue"),
          };
        case "businessName":
          // this.model = this.model || this.documentModel['company.name'] || "";
          return {
            binding: this.inputBinding,
            component: () =>
              import(
                "../../FormFields/GeneralInfoFields/BusinessNameField.vue"
              ),
          };
        case "email":
          // this.model = this.model || this.documentModel['company.email'] || "";
          return {
            binding: this.inputBinding,
            component: () =>
              import("../../FormFields/GeneralInfoFields/EmailField.vue"),
          };
        case "phone":
          // this.model = this.model || this.documentModel['company.phone'] || "";
          return {
            binding: this.inputBinding,
            component: () =>
              import("../../FormFields/GeneralInfoFields/PhoneInputField.vue"),
          };
        case "fax":
          // this.model = this.model || this.documentModel['company.fax'] || "";
          return {
            binding: {
              ...this.inputBinding,
              fax: true,
            },
            component: () =>
              import("../../FormFields/GeneralInfoFields/PhoneInputField.vue"),
          };
        case "country":
          // this.model = this.model || this.documentModel['company.country'] || "";
          return {
            binding: this.inputBinding,
            component: () =>
              import(
                "../../FormFields/GeneralInfoFields/CountryInputField.vue"
              ),
          };
        case "state":
          // this.model = this.model || this.documentModel['company.state'] || "";
          return {
            binding: this.inputBinding,
            component: () =>
              import("../../FormFields/GeneralInfoFields/StateSelectField.vue"),
          };
        case "websiteUrl":
          // this.model = this.model || this.documentModel['website_url'] || "";
          return {
            binding: this.inputBinding,
            component: () =>
              import("../../FormFields/GeneralInfoFields/WebsiteUrlField.vue"),
          };
        case "address":
          return {
            binding: this.inputBinding,
            component: () =>
              import("../../FormFields/GeneralInfoFields/AddressField.vue"),
          };
        case "date":
          return {
            binding: this.inputBinding,
            component: () => import("../../FormFields/Date.vue"),
          };
        case "checkbox":
          return {
            binding: { input: this.transformedInputs },
            component: () => import("../../FormFields/CheckboxInput.vue"),
          };
        case "checkboxes":
        case "multiSelect":
          return {
            binding: { input: this.transformedInputs },
            component: () => import("../../FormFields/MultiSelectField.vue"),
          };
        case "addOption":
          return {
            binding: {
              input: this.transformedInputs,
            },
            component: () => import("../../FormFields/AddOptionField.vue"),
          };
        case "addToList":
          return {
            binding: {
              input: this.transformedInputs,
            },
            component: () => import("../../FormFields/AddToListField.vue"),
          };
        case "combobox":
          return {
            binding: {
              input: this.transformedInputs,
            },
            component: () => import("../../FormFields/Combobox"),
          };
        case "dropdown":
          return {
            binding: {
              input: this.transformedInputs,
            },
            component: () => import("../../FormFields/Dropdown"),
          };
        case "custom":
          return {
            binding: {
              ...this.input.input,
              input: this.transformedInputs,
            },
            component: () =>
              import(
                `../../Questionnaire/Questions/CustomInputs/${this.input.component}`
              ),
          };
        case "textarea":
          return {
            binding: {
              input: this.transformedInputs,
            },
            component: () => import("../../FormFields/TextArea"),
          };
        case "slider":
          return {
            binding: {
              input: this.transformedInputs,
            },
            component: () => import("../../FormFields/SliderInput"),
          };
        case "text":
        default:
          return {
            binding: { input: this.transformedInputs },
            component: () => import("../../FormFields/TextInput.vue"),
          };
      }
    },
    existingErrors() {
      return this.currentErrors ? this.currentErrors[this.input.model] : null;
    },
    inputBinding() {
      const predefinedBindings = {
        label: this.input.label,
      };

      if (isFunction(this.input.input)) {
        return {
          ...predefinedBindings,
          ...this.input.input(this.documentModel),
        };
      }

      return {
        ...predefinedBindings,
        ...this.input.input,
      };
    },
    transformedInputs() {
      const rules =
        this.input?.rules?.map((rule) => {
          if (typeof rule === "string") return keyToValidationFunction(rule);

          return rule;
        }) || [];

      const inputRules =
        this.input?.inputRules?.map((rule) => {
          if (typeof rule === "string") return keyToValidationFunction(rule);

          return rule;
        }) || [];

      // Transform Subinput rules
      const inputs =
        this.input?.inputs?.map((subinput) => {
          return {
            ...subinput,
            rules:
              subinput.rules?.map((rule) => {
                if (typeof rule === "string") {
                  console.log("transform", {
                    rule: keyToValidationFunction(rule),
                  });
                  return keyToValidationFunction(rule);
                }

                return rule;
              }) || [],
          };
        }) || [];

      return {
        ...this.input,
        rules,
        inputRules,
        inputs,
      };
    },
    inputKey() {
      return this.input.type + this.input.model;
    },
  },
  methods: {
    inputTitleResult(title) {
      if (typeof title === "function") {
        return title(this.documentModel);
      }
      return title;
    },
    validate() {
      //Validate component
      try {
        if (this.$refs[`input_${this.inputKey}`]?.validate() === false) {
          return false;
        }
      } catch {
        /* Do nothing */
      }

      try {
        if (isFunction(this.input.validate)) {
          const message = this.input.validate(this.model);
          this.error = message;
          return !message;
        }
      } catch (e) {
        console.warn("e: ", e.message);
      }
      return true;
    },
  },
  watch: {
    model(newInput) {
      this.$emit("input", newInput);
    },
  },
};
</script>

<style scoped></style>
