<template>
  <component
    class="question-alert"
    :is="component"
    v-bind="params"
    v-show="conditionalShow"
  >
    {{ alert.body }}
  </component>
</template>

<script>
import { isFunction } from "lodash";
import { getConditionalFunction } from "../../../utils/questionGeneratorHelpers";

export default {
  name: "AlertGenerator",
  props: {
    alert: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    component() {
      return alert.component
        ? alert.component
        : () => import("@/components/Questionnaire/Alert.vue");
    },
    params() {
      if (isFunction(this.alert.params)) {
        return this.alert.params(this);
      }

      return this.alert.params || {};
    },
    conditionalShow() {
      // No Conditions exists
      if (!this.alert.condition && !this.alert.conditions) {
        return true;
      }

      let show = true;

      if (this.alert.condition && isFunction(this.alert.condition))
        show = this.alert.condition(this.model);

      if (this.alert.conditions) {
        const model = this.model;
        show = this.alert.conditions.reduce(function (
          result,
          currentCondition
        ) {
          const conditionalFunction = getConditionalFunction(currentCondition);

          return (
            result && conditionalFunction(model[currentCondition.variable])
          );
        },
        true);
      }

      return show;
    },
  },
};
</script>

<style lang="scss" scoped></style>
