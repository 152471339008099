<template>
  <div>
    <div v-if="showQuestions">
      <question-generator
        class="th-mx-auto"
        :questions="questions"
        :currentQuestionnaireStep="currentQuestion"
        v-model="dataModel"
        @next="nextQuestion"
        @previous="previousQuestion"
        @submit="submit"
        :loading="inProgress"
        :current-errors="currentErrors"
      />

      <question-error-modal v-model="showErrorModal" :error="error" />
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      v-else
    >
      <h4>
        <slot name="title" />
      </h4>
      <p class="body2">
        <slot name="subtitle" />
      </p>
      <v-btn color="primary" @click="showQuestions = true" class="th-mt-24">
        {{ $translation.t("next") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import QuestionGenerator from "../../../components/Questionnaire/Questions/QuestionGenerator";
import QuestionErrorModal from "../../../components/Questionnaire/QuestionErrorModal";
import { cloneDeep } from "lodash";
import { mapMutations, mapState } from "vuex";

export default {
  name: "ProductStepWrapper",
  components: { QuestionErrorModal, QuestionGenerator },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    defaultModel: Object,
    submitFunction: Function,
    onSuccess: Function,
    skipIntro: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataModel: {},
      showErrorModal: false,
      error: null,
      inProgress: false,
      showQuestions: this.skipIntro,
    };
  },
  created() {
    // this.showQuestions = this.skipIntro;
    Object.assign(this.dataModel, this.defaultModel);
  },
  computed: {
    ...mapState({
      currentQuestion: (state) => state.questionnaire.currentQuestion,
      currentErrors: (state) => state.questionnaire.currentErrors,
    }),
  },
  methods: {
    nextQuestion(questionObject = {}) {
      const { q, questionNumber } = questionObject;
      if (questionNumber) {
        this.SET_CURRENT_QUESTION(questionNumber);
        this.SET_CURRENT_QUESTION_STATUS({
          q: questionNumber - 1,
          visited: true,
        });

        // this.$event(
        //   "new_project_document_create_progress",
        //   {},
        //   "document.create.progress"
        // );
      }
      if (q) this.REMOVE_CURRENT_ERROR(q);

      this.SET_DOCUMENT_DATA({
        type: "company_info",
        data: cloneDeep(this.dataModel),
      });
    },
    previousQuestion(questionObject = {}) {
      const { questionNumber } = questionObject;

      this.SET_CURRENT_QUESTION(questionNumber);
    },
    async submit(model) {
      // Save last question
      this.nextQuestion();

      // Save Data to backend
      const dataSaved = await this.saveData(model);

      // Redirect to next step
      if (!dataSaved || this.error) return;

      //clear document data from storage
      this.SET_CURRENT_QUESTION(1);

      if (typeof this.onSuccess === "function") return this.onSuccess();
    },
    async saveData(model) {
      this.inProgress = true;

      try {
        const result = await this.submitFunction(model);

        if (!result && this.currentErrors) {
          this.showErrorModal = true;
        }

        // this.$event("", {
        //   error: e.response.data.message,
        // });

        //clear document data from storage
        this.SET_CURRENT_QUESTION(1);

        this.inProgress = false;

        return !!result;
      } catch (e) {
        // this.$event("new_project_document_create_error", {
        //   error: e.response.data.message,
        // });

        if (e.showError !== false) {
          this.$toasted.error("Error occurred while creating your document", {
            icon: "mdi-close-octagon-outline",
          });

          this.SET_CURRENT_ERRORS(e?.response?.data?.inputs);

          this.error = e.response?.data?.message;
          this.showErrorModal = true;
        }

        this.inProgress = false;

        this.$log.notify(e);
        return false;
      }
    },
    ...mapMutations("questionnaire", [
      "SET_CURRENT_QUESTION",
      "SET_CURRENT_ERRORS",
      "SET_DOCUMENT_DATA",
      "REMOVE_CURRENT_ERROR",
      "SET_CURRENT_QUESTION_STATUS",
    ]),
  },
  beforeDestroy() {
    this.$store.commit("questionnaire/DELETE_CURRENT_QUESTION_STATUSES");
  },
};
</script>

<style lang="scss" scoped></style>
